import { useEffect, useState } from "react";

export const useCanvasSize = () => {
  const [canvasSize, setCanvasSite] = useState<number | null>(null);
  // todo: update with window resize
  useEffect(() => {
    const x = window.innerWidth;
    const y = window.innerHeight;
    if (x < 540) {
      setCanvasSite(window.innerWidth - 40);
    } else {
      const min = x < y ? x : y;
      setCanvasSite(min < 1100 ? min - 100 : 1000);
    }
  }, []);
  return canvasSize;
};
